import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import storage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

import { Action } from "@ctra/utils";

import { EnterpriseAppState } from "./state";

/**
 * @todo import new reducers here
 */
import async from "../modules/async";
import auth from "../modules/auth";
import farms from "../modules/farms";
import breadcrumbs from "../modules/breadcrumbs";
import users from "../modules/user";
import dataDictionary from "../modules/data-dictionary";
import charts from "../modules/chart";
import scorecard from "../modules/scorecard";
import herdGroups from "../modules/herd-groups";
import pens from "../modules/pens";
import dimGroups from "../modules/dim-groups";
import insights, { settingsReducer as insightSettings } from "../modules/insights";
import cows from "../modules/cows";
import preferences from "../modules/preferences";
import events from "../modules/events";
import savedCards from "../modules/saved-cards";
import impactTracking from "../modules/impact-tracking";
import invitations from "../modules/invitation";
import session from "../modules/session";
import account from "../modules/account";
import farmSummaries from "../modules/farm-summaries";
import { farmInputDatasets, farmInputData } from "../modules/iofc";

import { getVersion, getStorageKey } from "../utils/versioning";

/**
 * @todo bump version between releases
 */
const persistConfig = {
  version: getVersion(),
  key: getStorageKey("enterprise"),
  blacklist: ["auth", "users", "async", "navigation", "session"],
  storage
};

export default (history: History): Reducer =>
  persistReducer<EnterpriseAppState, Action>(
    persistConfig,
    /**
     * @todo add new reducers here
     */
    combineReducers<EnterpriseAppState, Action>({
      router: connectRouter(history),
      // @ts-ignore - not an issue, just inconsistency between the original and our Reducer types
      session,
      cows,
      async,
      dataDictionary,
      charts,
      // @ts-ignore - not an issue, just inconsistency between the original and our Reducer types
      auth,
      users,
      herdGroups,
      pens,
      dimGroups,
      navigation: combineReducers({
        breadcrumbs
      }),
      farms,
      scorecard,
      insights,
      // @ts-ignore - not an issue, just inconsistency between the original and our Reducer types
      settings: combineReducers({
        farmInputDatasets,
        farmInputs: farmInputData,
        insights: insightSettings,
        farmSummaries
      }),
      preferences,
      events,
      savedCards,
      impactTracking,
      invitations,
      account
    })
  );
