import { Nullable } from "@ctra/utils";
import { FarmEntity } from "../farms";
import { UserEntity } from "../user";

export interface IOFCRequest {
  startDate: string;
  endDate: string;
  milkYieldSource: string;
  milkFatSource: string;
  milkProteinSource: string;
  avgFeedCost: Nullable<number>;
  avgMilkPrice: Nullable<number>;
  milkPriceClass: Array<1 | 2 | 3 | 4>;
  classWeights: Array<number>;
  units: "Imperial" | "SI";
}

/**
 * List of KPIs to pick from
 * @type {readonly [string, string, string, string, string, string, string, string, string, string, string, string, string]}
 */
export const IOFCKpiList = [
  "proteinPerc",
  "Butterfat",
  "Skim",
  "dmimilking",
  "Milk",
  "fatPerc",
  "feedcost",
  "inmilk",
  "yield",
  "Iofc",
  "EffectiveClass1MilkPrice",
  "EffectiveClass3MilkPrice",
  "exist",
  "MilkPickupFatPct"
] as const;

export type IOFCKPI = typeof IOFCKpiList[number];
export type IOFCRequestPayload = { farmId: FarmEntity["id"] } & IOFCRequest;

export type IOFCResponse = {
  data: Record<IOFCKPI, Array<number>> & { date: Array<string> };
  meta: { units: Record<IOFCKPI, string> };
};

export interface FarmInputDatasetField {
  name: "startTs" | "cost" | "price" | "unit";
  values?: string[];
  dataType: "date" | "dateTime" | "float" | "currency";
  boundaries?: [number, number];
  decimals?: number;
  isOptional: boolean;
}

export interface FarmInputDataset {
  name: string;
  fields: FarmInputDatasetField[];
  dedupKey: FarmInputDatasetField["name"][];
}

export interface FarmInputMeta {
  farmKey: string;
  farmId: number;
  resultCount: number;
  dataSet: string;
}

export type FarmInputValue<T extends string = "startTs"> = Record<string, unknown> & Record<T, unknown>;

export interface FarmInputServerValue extends FarmInputValue {
  createdTs: string; // "2024-11-22T14:17:30";
  userId: UserEntity["id"];
}

export interface FarmInputData extends FarmInputMeta {
  values: Array<FarmInputServerValue>;
}

export interface FarmInputDataResponse {
  meta: FarmInputMeta;
  data: Array<FarmInputServerValue>;
}
