import { createAsyncActions, AsyncActionCreator } from "@ctra/utils";

import {
  FarmInputData,
  FarmInputDataResponse,
  FarmInputDataset,
  FarmInputValue,
  IOFCRequestPayload,
  IOFCResponse
} from "./typings";
import types from "./types";
import { FarmEntity } from "../farms";

/**
 * Fetch IOFC data
 */
const fetchIOFCData = createAsyncActions(
  types.FETCH_IOFC_DATA,
  (payload: IOFCRequestPayload & { scenario: number }) => payload,
  (scenario: number, response: IOFCResponse) => ({
    ...response,
    scenario
  }),
  (scenario, error, statusCode, details) => ({
    scenario,
    error,
    statusCode,
    details
  })
);

/**
 * Fetch datasets to enable farm inputs for
 * @type {AsyncActionCreator<() => {}, (response: FarmInputDataset) => FarmInputDataset, (error, statusCode, details) => {details: string, error: string, statusCode: number}>}
 */
const fetchDatasets: AsyncActionCreator<
  () => Record<string, unknown>,
  (response: FarmInputDataset) => FarmInputDataset,
  (
    error: string,
    statusCode: number,
    details: string
  ) => {
    details: string;
    error: string;
    statusCode: number;
  }
> = createAsyncActions(
  types.FETCH_DATA_SETS,
  () => ({}),
  (response: FarmInputDataset): FarmInputDataset => response,
  (error, statusCode, details) => ({
    error,
    statusCode,
    details
  })
);

/**
 * Fetch farm inputs
 * @type {AsyncActionCreator<(farmId: FarmEntity["id"], datasetName: string) => {datasetName: string, farmId: number}, (datasetName: string, response: FarmInputData) => {data: Array<FarmInputServerValue>, meta: FarmInputMeta, datasetName: string, farmId: number}, (farmId: FarmEntity["id"], datasetName: string, error, statusCode, details) => {datasetName: string, details: string, farmId: number, error: string, statusCode: number}>}
 */
const fetchFarmInputs: AsyncActionCreator<
  (farmId: FarmEntity["id"], datasetName: string) => { datasetName: string; farmId: number },
  (
    datasetName: string,
    response: FarmInputDataResponse
  ) => FarmInputDataResponse & {
    farmId: FarmEntity["id"];
    datasetName: string;
  },
  (
    farmId: FarmEntity["id"],
    datasetName: string,
    error: string,
    statusCode: number,
    details: string
  ) => {
    farmId: FarmEntity["id"];
    datasetName: string;
    details: string;
    error: string;
    statusCode: number;
  }
> = createAsyncActions(
  types.FETCH_FARM_INPUTS,
  (farmId: FarmEntity["id"], datasetName: string) => ({
    datasetName,
    farmId
  }),
  (datasetName: string, response: FarmInputDataResponse) => ({
    ...response,
    farmId: response.meta.farmId,
    datasetName
  }),
  (farmId: FarmEntity["id"], datasetName: string, error, statusCode, details) => ({
    farmId,
    datasetName,
    error,
    statusCode,
    details
  })
);

/**
 * Adjust price for the given date
 * @type {AsyncActionCreator<(datasetName: string, farmId: FarmEntity["id"], value: FarmInputValue) => {datasetName: string, farmId: number, value: FarmInputValue}, (datasetName: string, response: FarmInputData) => {data: Array<FarmInputServerValue>, meta: FarmInputMeta, datasetName: string}, (error, statusCode, details) => {details: string, error: string, statusCode: number}>}
 */
const insertFarmInput: AsyncActionCreator<
  (
    farmId: FarmEntity["id"],
    datasetName: string,
    value: FarmInputValue
  ) => {
    datasetName: string;
    farmId: FarmEntity["id"];
    value: FarmInputValue;
  },
  (
    farmId: FarmEntity["id"],
    datasetName: string,
    response: FarmInputValue
  ) => FarmInputValue & {
    farmId: FarmEntity["id"];
    datasetName: string;
  },
  (
    datasetName: string,
    error: string,
    statusCode: number,
    details: string
  ) => {
    details: string;
    error: string;
    statusCode: number;
  }
> = createAsyncActions(
  types.INSERT_FARM_INPUT,
  (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => ({
    datasetName,
    farmId,
    value
  }),
  (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => ({
    farmId,
    datasetName,
    ...value
  }),
  (datasetName, error, statusCode, details) => ({
    datasetName,
    error,
    statusCode,
    details
  })
);

/**
 * Delete price for the given date
 * @type {AsyncActionCreator<(farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => {datasetName: string, farmId: number, value: FarmInputValue}, (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => {[p: string]: unknown, datasetName: string, farmId: number}, (datasetName, error, statusCode, details) => {datasetName: any, details: any, error: any, statusCode: any}>}
 */
const deleteFarmInput: AsyncActionCreator<
  (
    farmId: FarmEntity["id"],
    datasetName: string,
    value: FarmInputValue
  ) => { datasetName: string; farmId: number; value: FarmInputValue },
  (
    farmId: FarmEntity["id"],
    datasetName: string,
    value: FarmInputValue
  ) => { [p: string]: unknown; datasetName: string; farmId: number },
  (
    datasetName: string,
    error: string,
    statusCode: number,
    details: string
  ) => {
    datasetName: string;
    details: string;
    error: string;
    statusCode: number;
  }
> = createAsyncActions(
  types.DELETE_FARM_INPUT,
  (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => ({
    datasetName,
    farmId,
    value
  }),
  (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => ({
    farmId,
    datasetName,
    ...value
  }),
  (datasetName, error, statusCode, details) => ({
    datasetName,
    error,
    statusCode,
    details
  })
);

export default {
  fetchIOFCData,
  fetchDatasets,
  fetchFarmInputs,
  insertFarmInput,
  deleteFarmInput
};
