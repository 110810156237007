import { Epic, StateObservable, ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AjaxResponse } from "rxjs/ajax";
import { of, Observable } from "rxjs";
import { Action } from "redux";
import _ from "lodash";

import { Debug } from "@ctra/utils";

import { EnterpriseAppState } from "../../enterprise";
import { makeAzureApiURL, Request as R } from "../../utils/ajax";
import { FarmInputData, FarmInputDataResponse, FarmInputDataset, IOFCResponse } from "./typings";
import actions from "./actions";
import types from "./types";

/**
 * Request IOFC data
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchIOFCData: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.FETCH_IOFC_DATA.pending),
    mergeMap<ReturnType<typeof actions.fetchIOFCData.start>, Observable<Action>>(
      ({ payload: { scenario, farmId, startDate, endDate, ...rest } }) =>
        Request.POST<IOFCResponse>(
          makeAzureApiURL("analytics", "/labs/iofc/farm/<%= farmId %>")({ farmId }),
          {
            body: {
              startDate: startDate.replace(/[Z]/g, ""),
              endDate: endDate.replace(/[Z]/g, ""),
              ...rest
            }
          }
        ).pipe(
          map<AjaxResponse<IOFCResponse>, Action>(({ response }) => {
            return actions.fetchIOFCData.fulfill(scenario, response);
          }),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.iofcApi.error(error);
            }

            return of(actions.fetchIOFCData.reject(scenario, error, statusCode, details));
          })
        )
    )
  );

/**
 * Fetch datasets to enable farm inputs for
 * @param {Observable<Action>} action$
 * @param {StateObservable<EnterpriseAppState>} state$
 * @param {API} Request
 * @returns {Observable<Action>}
 */
const fetchDatasets: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.FETCH_DATA_SETS.pending),
    mergeMap<ReturnType<typeof actions.fetchDatasets.start>, Observable<Action>>(() =>
      Request.GET<FarmInputDataset>(makeAzureApiURL("data-api", "/data-sets")()).pipe(
        map<AjaxResponse<FarmInputDataset>, Action>(({ response }) => {
          return actions.fetchDatasets.fulfill(response);
        }),
        catchError<Action, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);
          const details = _.get(response, ["details"]);

          if (error) {
            Debug.iofcApi.error(error);
          }

          return of(actions.fetchDatasets.reject(error, statusCode, details));
        })
      )
    )
  );

/**
 * Fetch farm inputs
 * @param {Observable<Action>} action$
 * @param {StateObservable<EnterpriseAppState>} state$
 * @param {API} Request
 * @returns {Observable<Action>}
 */
const fetchFarmInputs: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.FETCH_FARM_INPUTS.pending),
    mergeMap<ReturnType<typeof actions.fetchFarmInputs.start>, Observable<Action>>(
      ({ payload: { datasetName, farmId } }) =>
        Request.GET<FarmInputDataResponse>(
          makeAzureApiURL(
            "data-api",
            "/data-sets/<%= datasetName %>"
          )({
            datasetName
          }),
          {
            body: {
              farmKey: farmId
            }
          }
        ).pipe(
          map<AjaxResponse<FarmInputDataResponse>, Action>(({ response }) => {
            return actions.fetchFarmInputs.fulfill(datasetName, response);
          }),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.iofcApi.error(error);
            }

            return of(actions.fetchFarmInputs.reject(farmId, datasetName, error, statusCode, details));
          })
        )
    )
  );

export const insertFarmInput: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.INSERT_FARM_INPUT.pending),
    mergeMap<ReturnType<typeof actions.insertFarmInput.start>, Observable<Action>>(
      ({ payload: { datasetName, farmId, value } }) =>
        Request.POST<FarmInputData>(
          makeAzureApiURL(
            "data-api",
            "/data-sets/<%= datasetName %>?farmKey=<%= farmId %>"
          )({
            farmId,
            datasetName
          }),
          {
            body: [value]
          }
        ).pipe(
          map<AjaxResponse<unknown>, Action>(() => {
            return actions.insertFarmInput.fulfill(farmId, datasetName, value);
          }),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.iofcApi.error(error);
            }

            return of(actions.insertFarmInput.reject(datasetName, error, statusCode, details));
          })
        )
    )
  );

/**
 * Delete farm input
 * @param {Observable<Action>} action$
 * @param {StateObservable<EnterpriseAppState>} state$
 * @param {API} Request
 * @returns {Observable<Action>}
 */
const deleteFarmInput: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.DELETE_FARM_INPUT.pending),
    mergeMap<ReturnType<typeof actions.deleteFarmInput.start>, Observable<Action>>(
      ({ payload: { datasetName, farmId, value } }) =>
        Request.DELETE<FarmInputData>(
          makeAzureApiURL(
            "data-api",
            "/data-sets/<%= datasetName %>?farmKey=<%= farmId %>"
          )({
            farmId,
            datasetName
          }),
          {
            body: [value]
          }
        ).pipe(
          map<AjaxResponse<unknown>, Action>(() => {
            return actions.deleteFarmInput.fulfill(farmId, datasetName, value);
          }),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.iofcApi.error(error);
            }

            return of(actions.deleteFarmInput.reject(datasetName, error, statusCode, details));
          })
        )
    )
  );

export default {
  fetchIOFCData,
  fetchDatasets,
  fetchFarmInputs,
  insertFarmInput,
  deleteFarmInput
};
