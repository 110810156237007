import { FC, useState } from "react";
import * as _ from "lodash";

import { GAEvent } from "@ctra/analytics";
import { MetricEntity } from "@ctra/api";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { SearchOutlined, AntDesignSelect as Select, Analytics, Tag, Row, Col } from "@ctra/components";

import styles from "./MetricPicker.module.less";
import { useDataDictionary } from "@base";
import { GACategories } from "@analytics";

const { OptGroup, Option } = Select;
const {
  V3: { CategoryIcon }
} = Analytics;

interface MetricPickerProps {
  onChange: (value: MetricEntity["id"]) => void;
  value?: MetricEntity["id"];
}
/**
 * Metric Picker component
 * @param param0
 * @returns
 */
export const MetricPicker: FC<MetricPickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>("");

  const {
    metrics,
    groups: { byFamilyCategory: groupedMetrics, families, projectionMetrics }
  } = useDataDictionary();

  const {
    layouts: { section },
    analytics: {
      config: {
        search: { placeholder },
        metric: { displayName },
        projections
      }
    }
  } = Content;

  /**
   * Get the dropdown data
   * @type {{}[]}
   */
  const items = _.map(_.keys(groupedMetrics), (category, idx) => ({
    key: idx,
    label: t<string>(section.title(category), { makeDefaultValue: true }),
    options: _.map(_.keys(_.get(groupedMetrics, [category])), (key) => {
      const lookup = [category, key, 0];

      const familyName = _.get(groupedMetrics, [...lookup, "family"]);
      const name = _.get(groupedMetrics, [...lookup, "name"]);
      const id = _.get(groupedMetrics, [...lookup, "id"]);
      const keywords = _.get(groupedMetrics, [...lookup, "keywords"], []);

      return {
        keywords,
        key: _.defaultTo(familyName, id),
        value: _.defaultTo(familyName, id),
        label: t(displayName(_.defaultTo(familyName, name)), { makeDefaultValue: true })
      };
    })
  }));

  /**
   * Filter the options based on search query
   */
  const filteredOptions = items.reduce((acc: typeof items, data) => {
    const filteredGroupOptions = data.options.filter(
      (option) =>
        option.label.toLowerCase().includes(filter.toLowerCase()) ||
        _.some(option.keywords, (keyword) => keyword.toLowerCase().includes(filter.toLowerCase()))
    );

    if (filteredGroupOptions.length > 0) {
      acc.push({
        ...data,
        options: filteredGroupOptions
      });
    }

    return acc;
  }, []);

  return (
    <div className={styles.Space}>
      {value ? (
        <CategoryIcon
          category={_.get(families, [value, 0, "category"], _.get(metrics, [value, "category"]))}
          className={styles.Icon}
        />
      ) : (
        <SearchOutlined className={styles.Icon} />
      )}
      <Select
        size="large"
        style={{ width: "100%" }}
        placeholder={t(placeholder)}
        onSearch={setFilter}
        onChange={(value) => {
          GAEvent(
            GACategories.configurator,
            "Select metric",
            JSON.stringify({ metric: value && _.get(metrics, [value, "name"], value) })
          );

          onChange(value);
        }}
        value={value}
        filterOption={false}
        showSearch
        showArrow={false}
      >
        {filteredOptions.map(({ label, options }) => (
          <OptGroup key={label} label={label}>
            {options.map(({ label, value }) => (
              <Option key={value} value={value}>
                <Row justify="space-between">
                  {label}
                  <Col>
                    {_.includes(projectionMetrics, value) && (
                      <Tag className={styles.Tag}>{t(projections)}</Tag>
                    )}
                  </Col>
                </Row>
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </div>
  );
};
