import { Action, Nullable } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { FarmInputData, FarmInputDataset } from "./typings";
import types from "./types";
import { FarmEntity } from "../farms";

export type FarmInputDatasetState = Nullable<FarmInputDataset[]>;

export type FarmInputDataState = {
  [farmId: FarmEntity["id"]]: {
    [datasetName: string]: FarmInputData;
  };
};

/**
 * Store farm input datasets
 * @type {(state: FarmInputDatasetState, action: Action) => FarmInputDatasetState}
 */
export const farmInputDatasets: (state: FarmInputDatasetState, action: Action) => FarmInputDatasetState =
  cleanup<FarmInputDatasetState>(null)((state, action) => {
    const { type, payload } = action;

    switch (type) {
      case types.FETCH_DATA_SETS.fulfilled: {
        return payload;
      }
      default:
        return state;
    }
  });

export const farmInputData: (state: FarmInputDataState, action: Action) => FarmInputDataState =
  cleanup<FarmInputDataState>({})((state, action) => {
    const { type, payload } = action;

    switch (type) {
      case types.INSERT_FARM_INPUT.fulfilled: {
        return {
          ...state,
          [payload.farmId]: {
            ...state[payload.farmId],
            [payload.datasetName]: {
              ...state[payload.farmId]?.[payload.datasetName],
              resultCount: (state[payload.farmId]?.[payload.datasetName]?.resultCount || 0) + 1,
              values: [...(state[payload.farmId]?.[payload.datasetName]?.values || []), payload]
            }
          }
        };
      }
      case types.DELETE_FARM_INPUT.fulfilled: {
        return {
          ...state,
          [payload.farmId]: {
            ...state[payload.farmId],
            [payload.datasetName]: {
              ...state[payload.farmId]?.[payload.datasetName],
              resultCount: (state[payload.farmId]?.[payload.datasetName]?.resultCount || 0) - 1,
              values: state[payload.farmId]?.[payload.datasetName]?.values?.filter(
                (item) => item.startTs !== payload.startTs
              )
            }
          }
        };
      }
      case types.FETCH_FARM_INPUTS.fulfilled: {
        const { farmId, datasetName, meta, data } = payload;

        return {
          ...state,
          [farmId]: {
            ...state[farmId],
            [datasetName]: {
              ...meta,
              values: data
            }
          }
        };
      }
      default:
        return state;
    }
  });
