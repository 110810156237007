import * as _ from "lodash";
import { useDeepCompareMemo } from "use-deep-compare";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { LibraryConfigType } from "../typings";
import { useChartContext } from "../providers";

const {
  iofc: {
    chart: { today: todayLabel }
  },
  chart: {
    tooltip: { contextLabel }
  }
} = Content;

/**
 * These configs are common to all charts
 * @todo rename `commonConfig` to `config`
 */
export function useCommonConfig<T extends LibraryConfigType = LibraryConfigType>(): { commonConfig: T } {
  const { meta } = useChartContext();
  const { t } = useTranslation();

  return useDeepCompareMemo<{ commonConfig: any }>(
    () => ({
      commonConfig: {
        animation: false,
        interactions: [{ type: "element-active" }],
        /**
         * Basic legend settings
         */
        legend: {
          layout: "horizontal",
          position: "top-left",
          flipPage: true,
          maxItemWidth: 120,
          maxRow: 2,
          offsetX: 24,
          itemName: {
            formatter: (text: string) => _.get(meta, ["series", "keys", text], text)
          }
        },
        /**
         * Basic tooltip settings
         */
        tooltip: {
          shared: false,
          position: "top",
          customItems: (originalItems: any[]) =>
            _.flatten(
              _.map(originalItems, (item) => {
                const context = _.get(item, ["data", "context"], null);

                return context
                  ? [
                      item,
                      // CSS will look for empty name span
                      {
                        name: "",
                        value: ""
                      },
                      ..._.map(
                        _.omitBy(context, (value, key) => _.startsWith(key, "_")),
                        (value, key) => ({
                          data: {},
                          mappingData: {},
                          name: t<string>(contextLabel(key)),
                          value: value
                        })
                      ),
                      // CSS will look for empty name span
                      {
                        name: "",
                        value: ""
                      }
                    ]
                  : item;
              })
            ),
          domStyles: {
            "g2-tooltip": {},
            "g2-tooltip-title": {
              color: "#666",
              margin: "10px 0 10px 16px"
            },
            "g2-tooltip-list": {},
            "g2-tooltip-list-item": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "14px",
              whiteSpace: "nowrap"
            },
            "g2-tooltip-marker": {
              borderRadius: "50%",
              display: "inline-block",
              height: "8px",
              marginRight: "8px",
              width: "8px"
            },
            "g2-tooltip-value": {
              fontWeight: "bold"
            },
            "g2-tooltip-name": {}
          },
          containerTpl: `
        <div class="g2-tooltip">
          <ul class="g2-tooltip-list"></ul>
          <div class="g2-tooltip-title"></div>
        </div>
      `,
          itemTpl: `
        <li class="g2-tooltip-list-item" data-index={index}>
          <span class="g2-tooltip-marker" style="background-color: {color};"></span>
          <span class="g2-tooltip-name">{name}</span>
          <span class="g2-tooltip-value">{value}</span>
        </li>
      `
        }
      }
    }),
    [meta]
  );
}
