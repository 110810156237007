import { Observable, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType, Epic, StateObservable } from "redux-observable";

import { Action } from "@ctra/utils";

import { makeAzureApiURL, withSandboxPrefix } from "../../utils/ajax";
import { types as iofcTypes } from "../iofc";
import { EnumList, MetricListResponse } from "./typings";
import types from "./types";
import actions from "./actions";

/**
 * Request a list of metrics
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchMetrics: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(
      types.FETCH_METRICS.pending,
      iofcTypes.INSERT_FARM_INPUT.fulfilled,
      iofcTypes.DELETE_FARM_INPUT.fulfilled
    ),
    switchMap(() =>
      Request.GET(makeAzureApiURL(withSandboxPrefix("analytics", state$), "/metrics/", {})()).pipe(
        map<{ response: MetricListResponse }, Action>(({ response }) =>
          actions.fetchMetrics.fulfill(response)
        ),
        catchError<unknown, Observable<Action>>(({ response }) =>
          of(actions.fetchMetrics.reject(response?.error))
        )
      )
    )
  );

/**
 * Request a list of all possible enums from BE
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchEnums: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.FETCH_ENUMS.pending),
    switchMap(() =>
      Request.GET(makeAzureApiURL(withSandboxPrefix("analytics", state$), "/meta/enums")()).pipe(
        map<{ response: EnumList }, Action>(({ response }) => actions.fetchEnums.fulfill(response)),
        catchError<unknown, Observable<Action>>(({ response }) =>
          of(actions.fetchEnums.reject(response?.error))
        )
      )
    )
  );

export default {
  fetchMetrics,
  fetchEnums
};
