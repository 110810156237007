import { useState } from "react";
import * as _ from "lodash";
import { BarConfig, Datum } from "@ant-design/charts";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";

import { BarData, ChartSeriesType } from "@ctra/api";
import { hslToHex } from "@ctra/utils";

import { useChartContext, useSeriesColor } from "../../../providers";
import { useCommonConfig } from "../../../hooks";
import { filterBarBySeries } from "../../../utils";
import "../Tooltip.css";

/**
 * Hook that merges bar config with the common config
 * The common config coming from the main app overrides the preset config
 * @returns
 */
export const useBarConfig = (): { config: BarConfig } => {
  const { commonConfig } = useCommonConfig();
  const { getColor } = useSeriesColor();

  const { series, data = [], meta } = useChartContext<BarData>();
  const [filteredData, setFilteredData] = useState(data);
  const { seriesType, series: metaSeries, yAxis: xAxis, axis } = _.defaultTo(meta, {});

  /**
   * Get the series keys from the metadata
   */
  const seriesKeys = _.get(meta, ["series", "keys"], {});

  /**
   * Count the number of items in the legend
   * @type {number}
   */
  const legendSize = _.size(series) || _.size(seriesKeys);

  useDeepCompareEffect(() => {
    setFilteredData(filterBarBySeries(data, meta, series));
  }, [data, meta, series]);

  /**
   * Bar chart config
   * @type {{yAxis: {line: {style: {stroke: string, lineWidth: number}}, label: {style: {fontSize: number, stroke: string, lineWidth: number}}, tickLine: {length: number, style: {stroke: string, lineWidth: number}}}, xAxis: {min: number, max: number, line: {style: {stroke: string, lineWidth: number}}, label: {offset: number}, title: {style: {fontSize: number, fontWeight: string}, text: string | undefined}, nice: boolean, tickLine: {length: number, style: {stroke: string, lineWidth: number}}}, xField: string, color: ({seriesField}: {seriesField: any}) => string, data: any[], yField: string, tooltip: {shared: boolean, customContent: (_title, content) => HTMLElement, showTitle: boolean}, seriesField: string, intervalPadding: number}}
   */
  const config: BarConfig = useDeepCompareMemo(
    () => ({
      color: _.memoize(
        ({ seriesField }: Datum) => {
          return hslToHex(getColor(seriesType as string, seriesField));
        },
        ({ seriesField }) => seriesField
      ),
      /**
       * turn off the legends if there is only one series
       */
      legend: legendSize > 1 ? commonConfig.legend : false,
      data: filteredData,
      seriesField: "seriesField",
      tooltip: {
        containerTpl: `
          <div class="g2-tooltip g2-barChart-tooltip">
            <ul class="g2-tooltip-list"></ul>
          </div>
        `,
        showTitle: false,
        formatter: (datum: Datum) => ({
          name: _.get(metaSeries, ["keys", datum.seriesField], datum.seriesField),
          value: _.round(datum.x, 2)
        })
      },
      xAxis: {
        label: {
          formatter: (value) => _.get(metaSeries, ["keys", value], value),
          offset: 10,
          autoRotate: true,
          autoHide: false,
          autoEllipsis: true
        },
        line: {
          style: {
            lineWidth: 0.5,
            stroke: "#000000"
          }
        },
        tickLine: {
          style: {
            lineWidth: 1,
            stroke: "#000000"
          }
        }
      },
      yAxis: {
        title: {
          style: {
            fontSize: 14,
            fontWeight: "bolder"
          },
          text: xAxis?.unit ? `${axis?.x.title}(${xAxis.unit})` : axis?.x.title
        },
        label: {
          style: {
            fontSize: 11,
            lineWidth: 0.5,
            stroke: "#000000"
          }
        },
        line: {
          style: {
            lineWidth: 0.5,
            stroke: "#000000"
          }
        },
        tickLine: {
          length: 5,
          style: {
            lineWidth: 1,
            stroke: "#000000"
          }
        }
      },
      xField: "y",
      yField: "x"
    }),
    [filteredData, metaSeries, seriesType, legendSize, commonConfig, axis, xAxis]
  );

  return useDeepCompareMemo(
    () => ({
      config: _.merge({}, commonConfig, config)
    }),
    [commonConfig, config]
  );
};
