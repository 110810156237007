import * as _ from "lodash";
import { memoize } from "@ctra/utils";

import { Base } from "./Base";

import { Series } from "../";
import { XAxisType } from "../xAxis";
import { DataPointsSource, DataPointsData } from "../typings";

/**
 * DataPoints Normalizer Base class
 */
class DataPointsBase extends Base {
  protected entity: DataPointsSource;

  constructor(source: DataPointsSource) {
    super(source);
    this.entity = source;
  }

  /**
   * Format the metadata for further processing e.g translations
   * @returns
   */
  @memoize
  formatMetadata(): DataPointsData["meta"] {
    return _.reduce<NonNullable<DataPointsSource["data"]>, NonNullable<DataPointsData["meta"]>>(
      this.entity.data,
      (acc, { points }, key) => {
        const series = Series.create(key, this.entity.meta.seriesType);
        const seriesField = series.getField();

        /**
         * Adding the series and axis labels to the metadata so that they can be translated by the consuming app.
         * @note Using _.setWith as it allows for a customizer because _.set creates arrays when the series are numeric values
         */
        if (seriesField) {
          _.setWith(acc, ["series", "keys", key], seriesField, Object);
        }

        const xType = XAxisType.create(this.entity.meta.xType);

        points.forEach(({ x }) => {
          const formattedPoint = xType.formatXAxis(x);

          _.setWith(acc, ["axis", "x", "labels", "short", formattedPoint], formattedPoint, Object);
        });

        return acc;
      },
      { ...this.entity.meta }
    );
  }
}

export { DataPointsBase };
