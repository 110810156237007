/* eslint @typescript-eslint/no-non-null-assertion: 0 */

import moment from "moment";
import * as _ from "lodash";
import { PartialDeep } from "type-fest";
import { AjaxError } from "rxjs/ajax";
import { createSelector } from "reselect";

import { EntitySelector, Nullable, Optional, TS, readableHash } from "@ctra/utils";

import { EnterpriseAppState } from "./state";
import { TokenEntity } from "../modules/auth";
import { FarmEntity, FarmList } from "../modules/farms";
import { BreadcrumbList } from "../modules/breadcrumbs";
import { UserEntity, UserList } from "../modules/user";
import { ChartEntity, ChartList, ChartData, Correlations, CorrelationList } from "../modules/chart";

import {
  DataDescriptorEntity,
  DataDescriptorList,
  EnumList,
  MetricCollection
} from "../modules/data-dictionary";

import { ScorecardValueEntity, ScorecardValueParams } from "../modules/scorecard";
import { makeScorecardHash } from "../modules/scorecard/utils";
import { HerdGroupEntity, HerdGroupList } from "../modules/herd-groups";
import { DIMGroupEntity, DIMGroupList } from "../modules/dim-groups";
import { PenEntity, PensList } from "../modules/pens";

import {
  GenericInsightEntity,
  NotificationMappingsState,
  InsightResolutionEntity,
  InsightTypeEntity,
  InsightValidation,
  InsightValidationEntity,
  MappingGroups
} from "../modules/insights";

import { KPIInsightSettingsState } from "../modules/insights";
import { DairyCowEntity } from "../modules/cows";
import { UserPreferencesEntity } from "../modules/preferences";

import {
  EventEntity,
  GroupedEventList,
  ExtendedEventList,
  ExtendedEventEntity,
  EventSourceType
} from "../modules/events";

import { SavedCards } from "../modules/saved-cards";
import { ErrorSource } from "../typings";

import {
  AnnotationList,
  FarmInputData,
  FarmInputDataset,
  FarmSummarySubscriptionList,
  InvitationList,
  InvitationType,
  SavedCardList
} from "../modules";

/**
 * Get the auth token info
 * @param {EnterpriseAppState} state
 */
export const getToken: EntitySelector<EnterpriseAppState, Record<string, unknown>, TokenEntity> = (state) =>
  state.auth.token;

/**
 * Tell whether the user access token is valid
 * @param {EnterpriseAppState} state
 * @private
 */
const _hasValidToken: EntitySelector<EnterpriseAppState, Record<string, unknown>, boolean> = (state) => {
  const expires = state.auth.token.expires;
  const now = moment();

  return expires ? moment(expires).isAfter(now) : false;
};

/**
 * Tell whether the current user is logged in
 * @param {EnterpriseAppState} state
 */
export const isLoggedIn: EntitySelector<EnterpriseAppState, Record<string, unknown>, boolean> = (state) =>
  !!getToken(state) && _hasValidToken(state);

/**
 * Tell if the user is logged out
 * @param {EnterpriseAppState} state
 */
export const hasLoggedOut: EntitySelector<EnterpriseAppState, never, boolean> = (state) => state.auth.logout;

/**
 * Get farm list for the logged in enterprise user
 * @param {EnterpriseAppState} state
 */
export const getFarmList: EntitySelector<EnterpriseAppState, Record<string, unknown>, FarmList> = (state) =>
  state.farms;

/**
 * Get a farm entity
 * @param {EnterpriseAppState} state
 * @param {FarmEntity["id"]} farmID
 */
export const getFarm: EntitySelector<EnterpriseAppState, { farmID?: FarmEntity["id"] }, FarmEntity> = (
  state,
  { farmID } = {}
) => {
  if (farmID) {
    return state.farms[farmID];
  }

  throw new Error(`You forgot to pass a "farmID" prop to the "getFarm" selector.`);
};

/**
 * Get the breadcrumbs
 * @param {EnterpriseAppState} state
 */
export const getBreadcrumbs: EntitySelector<EnterpriseAppState, Record<string, unknown>, BreadcrumbList> = (
  state
) => state.navigation.breadcrumbs;

/**
 * Get the username of the logged in user
 * @todo find a better name
 * @param state
 */
export const getUsername: EntitySelector<
  EnterpriseAppState,
  Record<string, unknown>,
  UserEntity["username"]
> = (state) => state.auth.user;

/**
 * Get all the users from the state
 * @param {EnterpriseAppState} state
 * @param {any} farmID
 * @return {any}
 */
export const getUserList: EntitySelector<EnterpriseAppState, { farmID: FarmEntity["id"] }, UserList> = (
  state,
  { farmID } = {}
) => {
  /**
   * Merge by omitting null-ish values, so newer values which are null will
   * not overwrite the older ones which are not null.
   * @type {{} & UserList}
   */
  const all = _.mergeWith({}, ..._.values(state.users), (objValue: UserEntity, srcValue: UserEntity) => ({
    ..._.omitBy(objValue, _.isNil),
    ..._.omitBy(srcValue, _.isNil)
  }));

  return farmID ? state.users[farmID] : all;
};

/**
 * Get a user from the state based on the given filters
 * @param {EnterpriseAppState} state
 * @param {Partial<UserEntity>} filter
 */
export const getUser: EntitySelector<EnterpriseAppState, Partial<UserEntity>, UserEntity | undefined> = (
  state,
  filter
) => {
  if (filter) {
    const userList = getUserList(state);

    return _.find(userList, (user, index, collection) => {
      return _.every(user, (v, k) => {
        const filterValue = filter[k as keyof UserEntity];

        return (
          _.isEqual(
            _.isString(filterValue) ? _.toLower(filterValue) : filterValue,
            _.isString(v) ? _.toLower(v) : v
          ) || !filter[k as keyof UserEntity]
        );
      });
    });
  }

  throw new Error(
    "You must pass a search query (filter) when you look for a user. You may want to use: getLoggedInUser"
  );
};

/**
 * Get the logged in user
 * @param {EnterpriseAppState} state
 */
export const getLoggedInUser: EntitySelector<EnterpriseAppState, Record<string, unknown>, UserEntity> = (
  state
) => {
  const username = getUsername(state);
  const user = username ? getUser(state, { email: username }) : void 0;

  if (!user) {
    throw new Error("You don't seem to be logged in yet.");
  }

  return user;
};

/**
 * Get full or filtered data dictionary
 * @param {EnterpriseAppState} state
 * @param {filter?:DataDescriptorEntity} props
 */
export const getDataDictionary: EntitySelector<
  EnterpriseAppState,
  { filter?: PartialDeep<DataDescriptorEntity> },
  DataDescriptorList
> = (state, props) =>
  props && _.isObject(props.filter)
    ? _.pickBy(state.dataDictionary.dataDescriptors, (dataDescriptor: DataDescriptorEntity) =>
        _.every<DataDescriptorEntity>(dataDescriptor, (value, key) => {
          /**
           * Get the value or search term from the filter
           */
          const filterValue = (props.filter as PartialDeep<DataDescriptorEntity>)[
            key as keyof DataDescriptorEntity
          ];

          let matches = _.isUndefined(filterValue) || _.isEqual(value, filterValue);

          if (!matches) {
            /**
             * Tell if we are allowed to look for partial matches in strings
             */
            const searchInString = _.isString(value) && _.isString(filterValue);

            /**
             * Arrays may overlap
             */
            const arrayOverlap = _.isArray(value) && _.isArray(filterValue);

            /**
             * Arrays may overlap
             */
            const objectOverlap = _.isObject(value) && _.isObject(filterValue);

            if (searchInString) {
              matches = _.includes(_.toLower(value as string), _.toLower(filterValue as string));
            } else if (arrayOverlap) {
              matches = !!_.intersection(value as Array<unknown>, filterValue as Array<unknown>).length;
            } else if (objectOverlap) {
              matches = _.every(filterValue as Record<string, unknown>, (v, k) => {
                const matchAgainst = (value as Record<string, unknown>)[k];
                const strings = _.isString(matchAgainst) && _.isString(v);

                return strings
                  ? _.toLower(matchAgainst as string).includes(_.toLower(v as string))
                  : matchAgainst === v;
              });
            }
          }

          return matches;
        })
      )
    : state.dataDictionary.dataDescriptors;

/**
 * Get a single data descriptor by id
 * @param {EnterpriseAppState} state
 * @param {{ id: DataDescriptorEntity.id }} props
 */
export const getDataDescriptor: EntitySelector<
  EnterpriseAppState,
  { id: DataDescriptorEntity["id"] },
  DataDescriptorEntity
> = (state, props) => {
  if (props && props.id) {
    return getDataDictionary(state)[props.id];
  }

  throw new Error("You must pass an id to retrieve a data descriptor from the store.");
};

/**
 * Get chart list
 * @param {EnterpriseAppState} state
 * @return {ChartList}
 */
export const getChartList: EntitySelector<EnterpriseAppState, Record<string, unknown>, ChartList> = (
  state
) => {
  return state.charts.config;
};

/**
 * Get chart entity from the store
 * @param {EnterpriseAppState} state
 * @param {{ id: ChartEntity.id }} props
 */
export const getChart: EntitySelector<
  EnterpriseAppState,
  { id: ChartEntity["id"] | Array<ChartEntity["id"]> },
  ChartEntity | ChartList
> = (state, props) => {
  if (props && _.isString(props.id)) {
    return state.charts.config[props.id] as ChartEntity;
  } else if (props && _.isArray(props.id)) {
    return _.pick(state.charts.config, props.id) as ChartList;
  }

  throw new Error("You must pass a chart id or a list of ids to retrieve charts from the store.");
};

/**
 * Get chart data
 * @param {EnterpriseAppState} state
 * @param {{ hash: string }} props
 */
export const getChartData: EntitySelector<EnterpriseAppState, { hash: string }, ChartData> = (
  state,
  props
) => {
  if (props && props.hash) {
    return state.charts.data[props.hash]?.data;
  }

  throw new Error("You must pass a hash (cache key) to retrieve a chart data from the store.");
};

/**
 * Get a cached iframe URL
 * @param {EnterpriseAppState} state
 * @param {{ hash: string }} props
 */
export const getChartError: EntitySelector<EnterpriseAppState, { hash: string }, AjaxError> = (
  state,
  props
) => {
  if (props && props.hash) {
    return state.charts.data[props.hash]?.error;
  }

  throw new Error("You must pass a hash (cache key) to retrieve an iframe URL from the store.");
};

/**
 * Get charts for the given data descriptor ids
 * @param {EnterpriseAppState} state
 * @param {Partial<{dataDescriptorIDs: Array<DataDescriptorEntity["id"]>}> | undefined} props
 * @return {ChartList}
 */
export const getChartsOfDataDescriptors: EntitySelector<
  EnterpriseAppState,
  { dataDescriptorIDs: Array<DataDescriptorEntity["id"]> },
  ChartList
> = (state, props) => {
  if (!props || !props.dataDescriptorIDs) {
    throw new Error("You need to pass a data descriptor (list) id to get its charts.");
  }

  const { dataDescriptorIDs } = props;

  /**
   * Get the data descriptors from the store
   * @type {Pick<DataDescriptorList, keyof DataDescriptorList>}
   */
  const dataDescriptors = _.pick(state.dataDictionary.dataDescriptors, dataDescriptorIDs);

  return _.reduce(
    dataDescriptors,
    (res, { supportedCharts }) => {
      const [main] = supportedCharts;

      return { ...res, [main]: state.charts.config[main] };
    },
    {}
  );
};

/**
 * Get a scorecard value of the given data descriptor, farm and query params
 * @param {EnterpriseAppState} state
 * @param {{dataDescriptorID, farmID, ...ScorecardValueParams}} props
 */
export const getScorecardValue: EntitySelector<
  EnterpriseAppState,
  {
    dataDescriptorID: DataDescriptorEntity["id"];
    farmID: FarmEntity["id"];
  } & ScorecardValueParams,
  ScorecardValueEntity
> = (state, props) => {
  const { dataDescriptorID, farmID, ...params } = props as Record<string, unknown>;

  const hash = makeScorecardHash(
    dataDescriptorID as string,
    farmID as number,
    params as ScorecardValueParams
  );

  return state.scorecard[hash];
};

/**
 * Get all the herd groups in the app
 * @param {EnterpriseAppState} state
 */
export const getHerdGroups: EntitySelector<
  EnterpriseAppState,
  { farmID?: FarmEntity["id"] | Array<FarmEntity["id"]> },
  HerdGroupList
> = (state, { farmID } = {}) => {
  let herdGroups;

  if (_.isArray(farmID)) {
    /**
     * If the farm id list is empty, then we may assume "all farms"
     */
    const farms = _.isEmpty(farmID) ? state.farms : _.pick(state.farms, farmID);

    /**
     * Pick the herd groups of each farm in the list
     * and make a flat array of them
     */
    herdGroups = _.flatMap(farms, "herdGroups");
  } else if (farmID) {
    herdGroups = _.get(state, ["farms", farmID, "herdGroups"], []);
  }

  return herdGroups ? _.pick(state.herdGroups, herdGroups) : state.herdGroups;
};

/**
 * Get all the pens in the app
 * @param {EnterpriseAppState} state
 * @param {FarmEntity["id"]} farmID
 * @returns {PensList}
 */
export const getPens: EntitySelector<
  EnterpriseAppState,
  { farmID?: FarmEntity["id"] | Array<FarmEntity["id"]> },
  PensList
> = (state, { farmID } = {}) => {
  let pens;

  if (_.isArray(farmID)) {
    /**
     * If the farm id list is empty, then we may assume "all farms"
     */
    const farms = _.isEmpty(farmID) ? state.farms : _.pick(state.farms, farmID);

    /**
     * Pick the pens of each farm in the list
     * and make a flat array of them
     */
    pens = _.flatMap(farms, "pens");
  } else if (farmID) {
    pens = _.get(state, ["farms", farmID, "pens"], []);
  }

  return pens ? _.pick(state.pens, pens) : state.pens;
};

/**
 * Get a herd group from the state
 * @param {EnterpriseAppState} state
 * @param {{id}} props
 */
export const getHerdGroup: EntitySelector<
  EnterpriseAppState,
  { id: HerdGroupEntity["id"] },
  HerdGroupEntity
> = (state, props) => {
  if (props && props.id) {
    return state.herdGroups[props.id];
  }

  throw new Error("I need a herd group id to resolve the herd group.");
};

/**
 * Get all the DIM groups in the app
 * @param {EnterpriseAppState} state
 * @param {FarmEntity["id"]} farmID
 */
export const getDIMGroups: EntitySelector<
  EnterpriseAppState,
  { farmID?: FarmEntity["id"] | Array<FarmEntity["id"]> },
  DIMGroupList
> = (state, { farmID } = {}) => {
  let dimGroups;

  if (_.isArray(farmID)) {
    /**
     * If the farm id list is empty, then we may assume "all farms"
     */
    const farms = _.isEmpty(farmID) ? state.farms : _.pick(state.farms, farmID);

    /**
     * Pick the DIM groups of each farm in the list
     * and make a flat array of them
     */
    dimGroups = _.flatMap(farms, "dimGroups");
  } else if (farmID) {
    dimGroups = state.farms[farmID].dimGroups;
  }

  return dimGroups ? _.pick(state.dimGroups, dimGroups) : state.dimGroups;
};

/**
 * Get a DIM group from the state
 * @param {EnterpriseAppState} state
 * @param {{id}} props
 */
export const getDIMGroup: EntitySelector<EnterpriseAppState, { id: DIMGroupEntity["id"] }, DIMGroupEntity> = (
  state,
  props
) => {
  if (props && props.id) {
    return state.dimGroups[props.id];
  }

  throw new Error("I need a DIM group id (key) to resolve the herd group.");
};

/**
 * Get a list of insights for the given query
 * @param {EnterpriseAppState} state
 * @param {{}} query
 * @param {string} list
 */
export const getInsightList: EntitySelector<
  EnterpriseAppState,
  { query?: Record<string, unknown>; list?: string },
  Nullable<Array<GenericInsightEntity>>
> = (state, { query, list } = {}) => {
  if (!query && !list) {
    throw new Error("You need to pass a list id or a query to get insights.");
  }

  const insightIDList = _.get(state.insights.lists, list || readableHash(query!));

  return insightIDList ? _.map(insightIDList, (id) => state.insights.entities[id]) : null;
};

/**
 * Get insights from state
 * @param {EnterpriseAppState} state
 */
export const getInsightSettings: EntitySelector<
  EnterpriseAppState,
  Record<string, unknown>,
  KPIInsightSettingsState
> = (state) => state.settings.insights.kpiInsights;

/**
 * Get insights
 * @param {EnterpriseAppState} state
 * @param {Array<GenericInsightEntity["id"]>} id
 */
export function getInsightsByID(
  state: EnterpriseAppState,
  { id }: { id: Array<GenericInsightEntity["id"]> }
): Array<GenericInsightEntity>;

/**
 * Get a single insight
 * @param {EnterpriseAppState} state
 * @param {GenericInsightEntity["id"]} id
 */
export function getInsightsByID(
  state: EnterpriseAppState,
  { id }: { id: GenericInsightEntity["id"] }
): GenericInsightEntity;

/**
 * Get insights by ID
 * @param {EnterpriseAppState} state
 * @param {Array<GenericInsightEntity["id"]> | GenericInsightEntity["id"]} id
 */
export function getInsightsByID(
  state: EnterpriseAppState,
  { id }: Record<"id", Array<GenericInsightEntity["id"]> | GenericInsightEntity["id"]>
): unknown {
  return _.isArray(id) ? _.map(id, (item) => state.insights.entities[item]) : state.insights.entities[id];
}

/**
 * Get the notification mappings from the state
 * @param {EnterpriseAppState} state
 */
export const getNotificationMappings: EntitySelector<EnterpriseAppState, never, NotificationMappingsState> = (
  state
) => state.settings.insights.mappings;

/**
 * Get the group based notification mappings from the state
 * @param {EnterpriseAppState} state
 */
export const getGroupMappings: EntitySelector<EnterpriseAppState, never, MappingGroups> = (state) =>
  state.settings.insights.mappingGroups;

/**
 * Get the individual notification mappings from the state
 * @param {EnterpriseAppState} state
 */
export const getIndividualMappings: EntitySelector<EnterpriseAppState, never, NotificationMappingsState> = (
  state
) => {
  const individualMappings = _.omitBy(state.settings.insights.mappings, ({ id }) =>
    _.flatMap(state.settings.insights.mappingGroups, "mappings").includes(id)
  );

  return individualMappings;
};

/**
 * Get all the insight types from the state
 * @param state
 */
export const getInsightTypes: EntitySelector<
  EnterpriseAppState,
  unknown,
  Record<InsightTypeEntity["typeName"], InsightTypeEntity>
> = (state) => state.insights.insightTypes;

/**
 * Get the insigt type entity from the state
 * @param {EnterpriseAppState} state
 * @param {{ id: InsightTypeEntity["typeName"] }} props
 */
export const getInsightType: EntitySelector<
  EnterpriseAppState,
  { typeName: InsightTypeEntity["typeName"] },
  InsightTypeEntity
> = (state, props) => {
  if (!props?.typeName) {
    throw new Error("You need to pass an insight type ID.");
  }

  return state.insights.insightTypes[props.typeName];
};

/**
 * Get insight validations by passing the insight id
 * @param {EnterpriseAppState} state
 * @param {{ id: GenericInsightEntity["id"] }} props
 */
export const getInsightValidations: EntitySelector<
  EnterpriseAppState,
  { genericInsightID: GenericInsightEntity["id"] },
  Array<InsightValidationEntity>
> = (state, props) => {
  if (!props?.genericInsightID) {
    throw new Error("You need to pass an insight ID.");
  }

  const validationIDs = state.insights.entities[props.genericInsightID].validations;

  return _.compact(_.map(validationIDs, (id) => (id ? state.insights.insightValidations[id] : null)));
};

/**
 * Get insight validation relevant to user by passing the insight id
 * @param {EnterpriseAppState} state
 * @param {{ id: GenericInsightEntity["id"] }} props
 */
export const getUserValidation: EntitySelector<
  EnterpriseAppState,
  { genericInsightID: GenericInsightEntity["id"] },
  Optional<InsightValidationEntity>
> = (state, props) => {
  if (!props?.genericInsightID) {
    throw new Error("You need to pass an insight ID.");
  }

  const validationIDs = state.insights.entities[props.genericInsightID].validations;

  const allValidations = _.compact(
    _.map(validationIDs, (id) => (id ? state.insights.insightValidations[id] : null))
  );

  /**
   * make sure only the user relevant validation is returned
   * make use of regex to check the validateBy ID
   */
  const userRegEx = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;
  const userValidation = _.find(allValidations, (validation) => userRegEx.test(validation.validatedBy!));

  return userValidation;
};

/**
 * Get the insight resolutions
 * @param state {EnterpriseAppState}
 * @param {{
 *  category?: InsightTypeEntity["insightCategory"];
 *  typeName?: InsightTypeEntity["typeName"];
 *  validation?: InsightValidation;
 * }} props
 */
export const getInsightResolutions: EntitySelector<
  EnterpriseAppState,
  {
    category?: InsightTypeEntity["insightCategory"];
    typeName?: InsightTypeEntity["typeName"];
    validation?: InsightValidation;
  },
  Record<InsightResolutionEntity["id"], InsightResolutionEntity>
> = (state, props = {}) => {
  let resolutions = state.insights.insightResolutions;

  if (props.validation) {
    resolutions = _.pickBy(resolutions, _.matchesProperty("validation", props.validation));
  }

  /**
   * Zip the other filters
   */
  const others = _.zip([props.category, props.typeName], ["insightCategory", "insightType"]) as Array<
    [typeof props.category | typeof props.typeName, "insightCategory" | "insightType"]
  >;

  _.forEach(others, (item) => {
    const [propValue, key] = item;

    if (propValue) {
      resolutions = _.pickBy(resolutions, (resolution) => {
        if (_.isEmpty(resolution[key])) {
          return true;
        }

        const { any, none } = resolution[key]!;

        /**
         * Tell if the given resolution is excluded
         */
        const excluded = !_.isEmpty(none) && none!.includes(propValue);

        return (!excluded && _.isEmpty(any)) || (!_.isEmpty(any) && any!.includes(propValue));
      });
    }
  });

  return resolutions;
};

/**
 * Get all the resolutions for the given insight
 * @param state
 * @param id
 */
export const getResolutionsOfInsight: EntitySelector<
  EnterpriseAppState,
  { id: GenericInsightEntity["id"] },
  Record<InsightResolutionEntity["id"], InsightResolutionEntity>
> = (state, { id } = {}) => {
  const insight = id ? state.insights.entities[id] : null;

  if (!insight) {
    throw new Error("You need to pass an insight ID.");
  }

  const { resolution } = insight;

  return _.pick(state.insights.insightResolutions, resolution);
};

/**
 * Get a cow from the state by its id
 * @param {EnterpriseAppState} state
 * @param {{ id: number }} props
 */
export const getDairyCow: EntitySelector<
  EnterpriseAppState,
  { id: DairyCowEntity["id"] },
  Optional<DairyCowEntity>
> = (state, props = {}) => (props.id ? state.cows[props.id] : void 0);

/**
 * Get user preferences
 * @param {EnterpriseAppState} state
 */
export const getUserPreferences: EntitySelector<EnterpriseAppState, UserPreferencesEntity> = (state) =>
  state.preferences;

/**
 * Get a single event
 * @param {EnterpriseAppState} state
 * @param {{id}} props
 */
export const getEvent: EntitySelector<
  EnterpriseAppState,
  { id: EventEntity["id"] },
  Optional<ExtendedEventEntity>
> = (state, props = {}) => {
  const { id } = props;

  if (!id) {
    throw new Error("I need a timeline event id to resolve the event.");
  }

  /**
   * Find its children (if there is any)
   * @type {EventEntity}
   */
  const child = _.find(state.events.entities, ({ context }) => _.get(context, "relatedAnnotationID") === id);

  return state.events.entities[id]
    ? {
        ...state.events.entities[id],
        children: child ? [child] : []
      }
    : void 0;
};

/**
 * Overload for getting an event list, grouped or plain
 * @param {EnterpriseAppState} state
 * @param {{hash: string, grouped?: T, limit?: number, exclude?: Array<EventSourceType>}} props
 * @returns {T extends true ? GroupedEventList : ExtendedEventList}
 */
export function getEventList<T extends Optional<boolean>>(
  state: EnterpriseAppState,
  props: {
    hash: string;
    grouped?: T;
    limit?: number;
    exclude?: Array<EventSourceType>;
  }
): T extends true ? GroupedEventList : ExtendedEventList;

/**
 * Get event list
 * @param {EnterpriseAppState} state
 * @param {{hash: string, grouped?: boolean, limit?: number, exclude?: Array<EventSourceType>}} props
 * @returns {unknown}
 */
export function getEventList(
  state: EnterpriseAppState,
  props: {
    hash: string;
    grouped?: boolean;
    limit?: number;
    exclude?: Array<EventSourceType>;
  }
): unknown {
  const { hash, grouped, limit, exclude } = props;
  const eventIDList = state.events.lists[hash];

  /**
   * Pick the events from the store which match the filters
   * @type {PartialObject<Record<string, EventEntity<EventContext>>>}
   */
  const events = exclude
    ? _.pickBy(state.events.entities, (entity) => {
        return (
          _.isArray(eventIDList) && eventIDList.includes(entity.id) && !exclude.includes(entity.source.type)
        );
      })
    : _.pick(state.events.entities, eventIDList);

  /**
   * Pick the events which have parent references
   * @type {Dictionary<EventEntity>}
   */
  const childEvents = _.pickBy(events, ({ context: { relatedAnnotationID } }) => !!relatedAnnotationID);

  /**
   * Pick the root events
   * @type {Dictionary<EventEntity>}
   */
  const rootEvents = _.omit(events, _.keys(childEvents));

  /**
   * Organise the child events by parent key for easier lookup
   * @type {Dictionary<EventEntity>}
   */
  const byParent = _.keyBy(
    childEvents,
    ({ context: { relatedAnnotationID } }) => relatedAnnotationID as EventEntity["id"]
  );

  /**
   * Reconstruct everything to a nested structure
   * @type {Dictionary<ExtendedEventEntity>}
   */
  const reconstructed = _.mapValues(rootEvents, ({ id, ...event }: EventEntity) => ({
    id,
    ...event,
    children: byParent[id] ? [byParent[id]] : []
  }));

  if (grouped) {
    const ordered = _.orderBy(reconstructed, ["startAt"], ["desc"]);

    return _.groupBy<ExtendedEventEntity>(limit ? _.take(ordered, limit) : ordered, ({ startAt }) =>
      TS.asMoment(startAt).format("MMM YYYY")
    );
  }

  return rootEvents;
}

/**
 * Get the count of any event list
 * @param state
 * @param hash
 */
export const getEventCount: EntitySelector<EnterpriseAppState, { hash: string }, number> = (
  state,
  { hash } = {}
) => _.keys(state.events.lists[hash!]).length;

/**
 * Tell if the user is impersonating
 * @param {EnterpriseAppState} state
 * @return {boolean}
 */
export const isImpersonating: EntitySelector<EnterpriseAppState, never, boolean> = (state) =>
  !!state.auth.impersonating;

/**
 * Get auth errors
 * @param state
 * @returns
 *
 * @todo if we start handling more errors, move them to a separate errors file
 */
export const getAuthError: EntitySelector<EnterpriseAppState, never, Nullable<ErrorSource>> = (state) =>
  state.auth.error;

/**
 * Find the correlations per farm
 * @param {EnterpriseAppState} state
 */
export const getCorrelations: EntitySelector<EnterpriseAppState, never, CorrelationList> = (state) =>
  state.charts.correlations;

/**
 * Find the correlated charts for a descriptor
 * @param {EnterpriseAppState} state
 * @param {any} farmID
 * @param {any} dataDescriptorID
 * @param {any} interval
 * @return {any}
 */
export const getCorrelatedCharts: EntitySelector<
  EnterpriseAppState,
  {
    dataDescriptorID: DataDescriptorEntity["id"];
    farmID: FarmEntity["id"];
    interval: number;
  },
  Optional<Correlations>
> = (state, { farmID, dataDescriptorID, interval } = {}) =>
  _.get(state, [
    "charts",
    "correlations",
    farmID!,
    "intervals",
    interval!,
    "descriptors",
    dataDescriptorID!,
    "correlations"
  ]) as Optional<Correlations>;

/**
 * Supported data descriptors for the particular interval
 * @param {EnterpriseAppState} state
 * @param {{ dataDescriptorID: number }} props
 */
export const getSupportedDescriptorsForInterval: EntitySelector<
  EnterpriseAppState,
  {
    farmID: FarmEntity["id"];
    interval: number;
  },
  Optional<Array<DataDescriptorEntity["id"]>>
> = (state, { farmID, interval } = {}) =>
  farmID && interval ? _.get(state, ["charts", "correlations", farmID, "index", interval], []) : void 0;

/**
 * Get saved chart cards list
 * @param {EnterpriseAppState} state
 * @param {{ pageKey: string }} props
 * @returns {SavedChartList}
 */
export const getSavedCards: EntitySelector<EnterpriseAppState, { pageKey: string }, SavedCardList> = (
  state,
  { pageKey } = {}
) => (pageKey && !_.isEmpty(state.savedCards) ? state.savedCards[pageKey] : state.savedCards["main"]);

/**
 * Get all saved charts in a dashboard
 * @param {EnterpriseAppState} state
 * @param {{ pageKey: string }} props
 * @returns {SavedChartList}
 */
export const getDashboard: EntitySelector<EnterpriseAppState, never, SavedCards> = (state) =>
  state.savedCards;

/**
 * Get annotation list
 * @param {EnterpriseAppState} state
 * @returns {AnnotationList}
 */
export const getAnnotationList: EntitySelector<EnterpriseAppState, never, AnnotationList> = (state) =>
  state.impactTracking.annotations;

/**
 * Get the list of all the referrals
 * @param state
 * @param {InvitationType} variant
 */
export const getReferrals: EntitySelector<EnterpriseAppState, { variant: InvitationType }, InvitationList> = (
  state,
  { variant } = { variant: InvitationType.sent }
) => _.pick(state.invitations.entities, _.get(state, ["invitations", variant as InvitationType], []));

/**
 * Get full metrics list
 * @param {EnterpriseAppState} state
 */
export const getMetricList: EntitySelector<
  EnterpriseAppState,
  { farmID?: FarmEntity["id"] },
  MetricCollection
> = (state, props) => {
  const { dataDescriptors, metrics } = state.dataDictionary;

  const filteredMetrics = _.pickBy(metrics, (metric) =>
    _.some(metric.variants, (variantId) => {
      //@ts-ignore
      const variantDescriptor = _.get(dataDescriptors, variantId);
      return (
        variantDescriptor &&
        _.intersection(
          variantDescriptor.supportedFarms,
          props?.farmID ? [props.farmID] : _.map(state.farms, "id")
        ).length
      );
    })
  );

  return filteredMetrics;
};

/**
 * Tell if the user is admin
 * @param {EnterpriseAppState} state
 * @return {boolean}
 */
export const isAdmin: EntitySelector<EnterpriseAppState, never, boolean> = (state) => {
  let user;

  try {
    user = getLoggedInUser(state);
  } catch {
    return false;
  }

  return !!_.filter(user.roles, (role) => ["Admin"].includes(role)).length;
};

/**
 * Tell if the session has been reset
 * @param {EnterpriseAppState} state
 * @return {boolean}
 */
export const isSessionReset: EntitySelector<EnterpriseAppState, never, boolean> = (state) =>
  state.session.flags.isReset;

/**
 * Get all possible enums that are present in BE
 */
export const getEnums: EntitySelector<EnterpriseAppState, never, EnumList> = (state) =>
  state.dataDictionary.enums;

/**
 * Get the farm summary subscriptions
 * @param {EnterpriseAppState} state
 * @returns {FarmSummarySubscriptionList}
 */
export const getFarmSummarySubscriptions: EntitySelector<
  EnterpriseAppState,
  never,
  FarmSummarySubscriptionList
> = (state: EnterpriseAppState): FarmSummarySubscriptionList => state.settings.farmSummaries;

/**
 * Get the chart request URL
 * @param {EnterpriseAppState} state
 * @param {any} hash
 * @returns {any}
 */
export const getChartRequestURL: EntitySelector<EnterpriseAppState, { hash: string }, Nullable<string>> = (
  state: EnterpriseAppState,
  { hash }: { hash?: string } = {}
): Nullable<string> => (hash ? state.charts.data[hash]?.requestURL : null);

/**
 * Get a state slice for the chart sharing context
 * @param {EnterpriseAppState} state
 * @param {any} dataDescriptorID
 * @param {any} farmID
 * @returns {EnterpriseAppState}
 */
export const getChartSharingContext: EntitySelector<
  EnterpriseAppState,
  {
    dataDescriptorID: DataDescriptorEntity["id"];
    chartID: ChartEntity["id"];
    farmID?: FarmEntity["id"];
  },
  Partial<EnterpriseAppState>
> = createSelector(
  (state: EnterpriseAppState, { dataDescriptorID, chartID, farmID } = {}) => {
    if (dataDescriptorID && chartID) {
      const descriptorPath = `dataDictionary.dataDescriptors.${dataDescriptorID}`;
      const chartPath = `charts.config.${chartID}`;

      let farmPath,
        herdGroupPath = null,
        dimGroupPath = null,
        penPath = null;

      if (farmID) {
        farmPath = `farms.${farmID}`;
        const farm = state.farms[farmID];

        if (farm) {
          const herdGroups = farm.herdGroups;
          const pens = farm.pens;
          const dimGroups = farm.dimGroups;

          herdGroupPath = _.map(_.compact(herdGroups), (id) => `herdGroups.${id}`);
          penPath = _.map(_.compact(pens), (id) => `pens.${id}`);
          dimGroupPath = _.map(_.compact(dimGroups), (id) => `dimGroups.${id}`);
        }
      } else {
        const supportedFarms = getDataDescriptor(state, { id: dataDescriptorID }).supportedFarms;

        farmPath = _.map(supportedFarms, (id) => `farms.${id}`);
      }

      return _.mapValues(
        _.pick(
          state,
          ..._.flatten(_.compact([descriptorPath, chartPath, farmPath, herdGroupPath, dimGroupPath, penPath]))
        ),
        (v) => (_.isArray(v) ? _.keyBy(_.compact(v), "id") : v)
      );
    }

    return {};
  },
  (result) => result
);

/**
 * Get the farm input datasets
 * @type {OutputSelector<[(state: EnterpriseAppState) => FarmInputDataset | null], any, ((...args: SelectorResultArray<[(state: EnterpriseAppState) => FarmInputDataset | null]>) => any) & Pick<ReturnType<typeof defaultMemoize>, keyof ReturnType<typeof defaultMemoize>>, GetParamsFromSelectors<[(state: EnterpriseAppState) => FarmInputDataset | null]>> & Pick<ReturnType<typeof defaultMemoize>, keyof ReturnType<typeof defaultMemoize>>}
 */
export const getFarmInputDatasets: EntitySelector<EnterpriseAppState, never, Nullable<FarmInputDataset[]>> =
  createSelector(
    (state: EnterpriseAppState) => _.get(state, "settings.farmInputDatasets", null),
    (r) => r
  );

/**
 * Get the farm input data
 * @type {OutputSelector<[(state: EnterpriseAppState, {farmId}: {farmId: any}) => any], any, ((...args: SelectorResultArray<[(state: EnterpriseAppState, {farmId}: {farmId: any}) => any]>) => any) & Pick<ReturnType<typeof defaultMemoize>, keyof ReturnType<typeof defaultMemoize>>, GetParamsFromSelectors<[(state: EnterpriseAppState, {farmId}: {farmId: any}) => any]>> & Pick<ReturnType<typeof defaultMemoize>, keyof ReturnType<typeof defaultMemoize>>}
 */
export const getFarmInputs: EntitySelector<
  EnterpriseAppState,
  { farmId: FarmEntity["id"] },
  Nullable<Record<string, FarmInputData>>
> = createSelector(
  (state: EnterpriseAppState, { farmId }) => _.get(state, ["settings", "farmInputs", farmId], null),
  (r) => r
);
