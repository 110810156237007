import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import * as _ from "lodash";

import {
  Button,
  Typography,
  Space,
  AntDesignInput,
  Icon,
  Barn,
  AntDesignSelect,
  Modal,
  CopyOutlined,
  Alert,
  List,
  InfoCircleFilled,
  message
} from "@ctra/components";

import { Optional } from "@ctra/utils";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import {
  Enterprise,
  EnterpriseAppState,
  FarmEntity,
  MetricCollection,
  MetricEntity,
  SavedCardList,
  SavedCards
} from "@ctra/api";

import { useFarm, useFarmList } from "@farms";
import { GACategories, useSavedCharts } from "@analytics";
import { useDataDictionary } from "@base";
import { commonModalProps } from "../DashboardPage";

import styles from "./CopyDashboardModal.module.less";

const { Group } = AntDesignInput;
const { Text } = Typography;

interface CopyDashboardModalProps {
  visible: boolean;
  group: Optional<string>;
  onCancel: () => void;
}
/**
 * Modal to copy tab from one farm to another
 * @param param0
 * @returns
 */
export const CopyDashboardModal: FC<CopyDashboardModalProps> = ({ visible, group, onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { farm } = useFarm();
  const { farmList } = useFarmList();
  const { savedCharts } = useSavedCharts();

  const groupName = _.find(savedCharts, { id: group })?.groupName;

  const groupCopy = `${groupName}-copy`;
  const [newTabLabel, setNewTabLabel] = useState(groupCopy);
  const [copyFarm, setCopyFarm] = useState<Optional<FarmEntity["id"]>>(void 0);
  const [unsupportedVariants, setUnsupportedVariants] = useState<Optional<MetricEntity["variants"]>>();

  const {
    api: { extractSource, extractChartInfo }
  } = useDataDictionary();

  const {
    analytics: {
      v3: {
        confirm: {
          newTab,
          copyTab,
          error: { duplicateTab }
        }
      },
      config: {
        metric: { displayName }
      }
    },
    navigation,
    timeline: {
      createEvent: { submit }
    }
  } = Content;

  /**
   * Attempt to get metrics for the copy farm
   */
  const metrics = useSelector<EnterpriseAppState, MetricCollection>((state) =>
    Enterprise.entities.getMetricList(state, { farmID: copyFarm })
  );

  /**
   * Attempt to get the saved charts for copy farm
   */
  const copyFarmTabs = useSelector<EnterpriseAppState, SavedCardList>((state) =>
    Enterprise.entities.getSavedCards(state, { pageKey: copyFarm?.toString() })
  );

  const currentCharts = _.find(savedCharts, { id: group })?.cards;

  /**
   * Set the non supported variants upon select
   */
  useEffect(() => {
    const supportedVariants = _.flatMap(metrics, ({ variants }) => _.map(variants));
    const currentVariants = _.map(currentCharts, "variantId");

    setNewTabLabel(groupCopy);
    setUnsupportedVariants(_.difference(currentVariants, supportedVariants));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyFarm, group, currentCharts]);

  /**
   * Alert to be shown if non supported metrics
   */
  const nonSupportedAlert = unsupportedVariants?.length ? (
    <Alert
      className={styles.Alert}
      type="warning"
      message={
        <>
          <Space>
            <InfoCircleFilled />
            {t(copyTab.alert)}
          </Space>
          <List
            bordered={false}
            dataSource={unsupportedVariants}
            renderItem={(variant) => {
              const source = extractSource(variant);

              const chartInfo = source && extractChartInfo(source);

              return (
                <List.Item className={styles.List}>
                  {source && chartInfo && (
                    <Text>- {t(displayName(chartInfo.nameToDisplay), { makeDefaultValue: true })}</Text>
                  )}
                </List.Item>
              );
            }}
          />
        </>
      }
    />
  ) : null;

  /**
   * Handle copying of tab to other farm
   */
  const handleSave = () => {
    const updatedCharts =
      currentCharts &&
      _.filter(currentCharts, ({ variantId: variantID }) => !_.includes(unsupportedVariants, variantID));

    const updatedTabs = [...copyFarmTabs, { groupName: newTabLabel, cards: updatedCharts }];

    /**
     * Check if this tab already exists
     */
    if (_.includes(_.map(copyFarmTabs, "groupName"), newTabLabel)) {
      message.error(t(duplicateTab));
    } else {
      dispatch(SavedCards.actions.updateTabs.start(copyFarm, updatedTabs));
    }

    onCancel();
  };

  /**
   * Keep farm selection disabled if
   * on all farms view, empty farm list, or if there is only one farm
   */
  const selectDisabled = _.isEmpty(farmList) || !farm || _.keys(farmList).length === 1;

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      {...commonModalProps}
      okButtonProps={{ disabled: selectDisabled, ...commonModalProps.okButtonProps }}
      className={styles.Modal}
      okText={t(submit)}
      onOk={handleSave}
      data-gtm-category={GACategories.dashboards}
      data-gtm-action="Copy dashboard"
      data-gtm-info={JSON.stringify({ dashboard: groupName })}
      title={
        <Space>
          <CopyOutlined />
          {t(copyTab.title)}
        </Space>
      }
    >
      <Space direction="vertical">
        <Text>{t(copyTab.dashboard)}</Text>
        <AntDesignInput
          placeholder={t(newTab.inputPlaceholder)}
          value={newTabLabel}
          className={styles.Margin}
          onChange={({ target: { value } }) => {
            setNewTabLabel(value);
          }}
        />
        <Text className={styles.Farm}>{t(copyTab.farm)}</Text>
        <Group compact className={styles.InputGroup}>
          <Button size="middle" className={styles.Icon} icon={<Icon component={Barn} />} disabled />
          <AntDesignSelect
            showSearch
            placeholder="select farm"
            className={styles.Select}
            disabled={selectDisabled}
            value={copyFarm}
            showArrow={true}
            size="middle"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: t<string>(navigation.main.allFarms), value: null },
              ..._.map(farm && _.omit(farmList, [farm?.id]), ({ name, id }) => ({
                label: name,
                value: id
              }))
            ]}
            onSelect={setCopyFarm}
          />
        </Group>
      </Space>
      {nonSupportedAlert}
    </Modal>
  );
};
