import { FC, useEffect, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import { isDispatched, Nullable } from "@ctra/utils";

import { Enterprise, EnterpriseAppState, User, UserEntity, UserMetadata, Role, FarmEntity } from "@ctra/api";

interface ContextType {
  user: Partial<UserEntity>;
  role: Role;
  meta: {
    isLoading: boolean;
    isAdmin: boolean;
    isSignupCompleted: boolean;
    isFarmer: boolean;
  };
  api: {
    isFarmerOf: (farmID: Nullable<FarmEntity["id"]>) => boolean;
    getFullname: () => string;
  };
}

/**
 * Make a default context for user entities
 */
const DefaultContext = createContext<ContextType>({
  user: {},
  role: "Farmer",
  meta: {
    isLoading: true,
    isAdmin: false,
    isSignupCompleted: true,
    isFarmer: false
  },
  api: {
    getFullname: () => "",
    isFarmerOf: () => false
  }
});

/**
 * Fetch the current user object if it is not yet present in the store
 * @param children
 * @private
 */
const _UserProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Tell if the user ia logged in
   * @type {boolean}
   */
  const isLoggedIn = useSelector<EnterpriseAppState, boolean>(Enterprise.entities.isLoggedIn);

  /**
   * Get the currently logged-in username from the auth state
   */
  const username = useSelector<EnterpriseAppState, UserEntity["username"]>(Enterprise.entities.getUsername);

  /**
   * Attempt to get the user object
   * @type {UserEntity | undefined}
   */
  const user = useSelector<EnterpriseAppState, UserEntity | undefined>((state) =>
    username
      ? Enterprise.entities.getUser(state, {
          email: username
        })
      : void 0
  );

  /**
   * Make a default signup state for the users whose signup metadata is null (aka old users)
   * @type {{role: string, isSignupCompleted: boolean}}
   */
  const signupState: NonNullable<UserMetadata["signup"]> = _.defaultTo(_.get(user, ["metadata", "signup"]), {
    isSignupCompleted: true,
    role: "Advisor"
  });

  /**
   * Check if the user is an admin
   * @type {boolean}
   */
  const isAdmin = useSelector<EnterpriseAppState, boolean>(Enterprise.entities.isAdmin);

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, User.types.FETCH_USER)
  );

  useEffect(() => {
    if (isLoggedIn && !(dispatched || user)) {
      dispatch(User.actions.fetchUser.start(username as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, dispatched, user, username]);

  return (
    <DefaultContext.Provider
      value={{
        api: {
          getFullname: () => (user ? _.compact([user.firstName, user.lastName]).join(" ") : ""),
          isFarmerOf: (farmID: Nullable<FarmEntity["id"]>) => user?.farmAccess?.farmId === farmID
        },
        user: user || {
          username
        },
        role: signupState.role,
        meta: {
          isFarmer: signupState.role === "Farmer" || !!user?.farmAccess?.farmId,
          isLoading: !user,
          isAdmin,
          isSignupCompleted: signupState.isSignupCompleted
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const UserContext = {
  Consumer: DefaultContext.Consumer,
  Provider: _UserProvider
};

/**
 * Hook to get the user info within the context
 */
export const useCurrentUser = (): ContextType => useContext(DefaultContext);
