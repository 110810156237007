import * as _ from "lodash";

import { memoize } from "@ctra/utils";
import { HeatmapSource, HeatmapData } from "../typings";
import { DataPointsBase } from "./DataPointsBase";

/**
 * Normalizer class for heat map data
 */
class HeatmapNormalizer extends DataPointsBase {
  protected entity: HeatmapSource;

  constructor(source: HeatmapSource) {
    super(source);
    this.entity = source;
  }

  /**
   * Process the incoming heat map data
   */
  @memoize
  formatData(): HeatmapData["data"] {
    const value = _.reduce<NonNullable<HeatmapSource["data"]>, NonNullable<HeatmapData["data"]>>(
      this.entity.data,
      (acc, item) => {
        const list = _.map(item.points, (point) => {
          return {
            x: point.x,
            y: point.y,
            z: _.round(_.round(point.z, 4) * 100, 2),
            context: point.context
          };
        });

        return [...acc, ...list];
      },
      []
    );

    const orderedPoints = _.sortBy(_.orderBy(value, ["y"], ["desc"]), (point) => point.x);

    return _.map(orderedPoints, (item) => {
      return { ...item, y: `${item.y}` };
    });
  }

  /**
   * Process the incoming heatmap meta data
   * @returns
   */
  @memoize
  formatMetadata(): HeatmapData["meta"] {
    return {
      ...super.formatMetadata(),
      seriesField: _.first(Object.keys(this.entity.data))
    };
  }
}

export { HeatmapNormalizer };
