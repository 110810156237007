import { Debug } from "@ctra/utils";

Debug.create("iofcApi", "IOFC API", { prefixColor: "#884ea0", transports: true });

export { default as types } from "./types";
export { default as actions } from "./actions";
export { default as epics } from "./epics";
export * from "./reducers";

export * from "./typings";
