import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/iofc";

/**
 * Fetch IOFC data
 * @category Type
 */
const FETCH_IOFC_DATA: AsyncType = createAsyncTypes(ns, "FETCH_IOFC_DATA", {
  preservesPayload: true,
  primary: "scenario"
});

/**
 * Fetch farm input datasets
 * @type {AsyncType}
 */
const FETCH_DATA_SETS: AsyncType = createAsyncTypes(ns, "FETCH_DATA_SETS");

/**
 * Fetch farm inputs
 * @type {AsyncType}
 */
const FETCH_FARM_INPUTS: AsyncType = createAsyncTypes(ns, "FETCH_FARM_INPUTS", {
  primary: "farmId"
});

/**
 * Adjust price
 * @type {AsyncType}
 */
const INSERT_FARM_INPUT: AsyncType = createAsyncTypes(ns, "INSERT_FARM_INPUT", {
  primary: "datasetName"
});

/**
 * Delete price
 * @type {AsyncType}
 */
const DELETE_FARM_INPUT: AsyncType = createAsyncTypes(ns, "DELETE_FARM_INPUT", {
  primary: "datasetName"
});

export default {
  FETCH_IOFC_DATA,
  FETCH_DATA_SETS,
  FETCH_FARM_INPUTS,
  INSERT_FARM_INPUT,
  DELETE_FARM_INPUT
};
