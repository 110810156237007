import { useEffect, useState } from "react";
import * as _ from "lodash";
import { AreaConfig } from "@ant-design/charts";

import { LineData } from "@ctra/api";
import { hslToHex } from "@ctra/utils";

import { useChartContext, useSeriesColor } from "../../../providers";
import { useCommonConfig } from "../../../hooks";
import { getStackedMax, getStackedMin } from "../../../utils";
import "../Tooltip.css";

/**
 * Hook that merges area config with the common config
 * The common config coming from the main app overrides the preset config
 * @returns
 */
export const useAreaConfig = (): { config: AreaConfig } => {
  const { getColor } = useSeriesColor();
  const { commonConfig } = useCommonConfig();

  // const {
  //   api: { filterBySeries }
  // } = useSeriesFilter();

  /* istanbul ignore next - ignoring default logic */
  const { data = [], meta, config: contextConfig } = useChartContext<LineData>();
  const { seriesType, axis } = _.defaultTo(meta, {});
  // const [data, setData] = useState(inputData);

  // useEffect(() => {
  //   setData(filterBySeries(inputData));
  // }, [filterBySeries, inputData]);

  /**
   * Area chart config
   * @type {{yAxis: {min: number | undefined, max: number | undefined, label: {formatter: (value) => number}, title: {text: string | undefined}}, xAxis: {line: {style: {stroke: string, lineWidth: number}}, label: {formatter: (value) => string | undefined, offset: number}, nice: boolean, tickLine: {length: number, style: {stroke: string, lineWidth: number}}}, xField: string, color: ({seriesField}: {seriesField: any}) => string, data: any[], yField: string, seriesField: string}}
   */
  /* istanbul ignore next - testing this proved a pain especially the callbacks */
  const config: AreaConfig = {
    color: ({ seriesField }) => hslToHex(getColor(seriesType, seriesField)),
    data,
    seriesField: "seriesField",
    xAxis: {
      label: {
        formatter: (value) => axis?.x.labels?.short[value],
        offset: 10
      },
      line: {
        style: {
          lineWidth: 0.5,
          stroke: "#000000"
        }
      },
      nice: true,
      tickLine: {
        length: 3,
        style: {
          lineWidth: 1,
          stroke: "#000000"
        }
      }
    },
    yAxis: {
      label: {
        formatter: (value) => {
          return _.round(parseFloat(value), 2);
        }
      },
      max: getStackedMax(data, "x", "y"),
      min: getStackedMin(data, "x", "y"),
      title: {
        text: axis?.y.title
      }
    },
    xField: "x",
    yField: "y"
  };

  return { config: _.merge({}, contextConfig, commonConfig, config) };
};
